/* eslint-disable react/prop-types */
import {
  Button, Container, Grid, Paper, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import {
  Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator,
} from '@mui/lab';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PaymentIcon from '@mui/icons-material/Payment';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';

const useStyles = makeStyles(() => ({
  whiteText: {
    color: '#D8C095',
  },
  paper: {
    padding: '1em 2em',
  },
  featureImage: {
    width: '100%',
  },
  secondaryBackground: {
    backgroundColor: '#3A4D60',
    padding: '10em 0',
  },
  feature: {
    backgroundColor: '#3A4D60',
  },
}));

export default function KatangaPage({ data }) {
  const classes = useStyles();
  const {
    studentList,
    studentDetails,
    logo,
    favicon,
  } = data;

  return (
    <Layout>
      <div style={{
        backgroundColor: 'white',
      }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Img
                className={classes.featureImage}
                fluid={logo ? logo.childImageSharp.fluid : null}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Img
                className={classes.featureImage}
                fluid={studentList ? studentList.childImageSharp.fluid : null}
              />
            </Grid>
          </Grid>
        </Container>
        <div style={{ width: '100vw' }} className={classes.secondaryBackground}>
          <Container maxWidth="lg">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={6}
            >
              <Grid item xs={2}>
                <Img
                  className={classes.featureImage}
                  fluid={favicon ? favicon.childImageSharp.fluid : null}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3" align="center" className={classes.whiteText} gutterBottom>
                  The leading school administration system with valuable reports and features
                  to help manage various administrative tasks such as admissions, payments &
                  enrollments.
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  variant="outlined"
                  color="secondary"
                  href="mailto:katanga@thinkzambia.org"
                >
                  Learn More
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Container maxWidth="md">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item xs={12} />
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Timeline position="alternate">
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot>
                      <PlaylistAddCheckIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={0}>
                      <Typography variant="h3" gutterBottom>
                        Easy student management
                      </Typography>
                      <Typography variant="body2">
                        Katanga organizes student records conveniently so you can focus on
                        running your school.
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot>
                      <TrendingUpIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={0}>
                      <Typography variant="h3" gutterBottom>
                        Useful Reports & Charts
                      </Typography>
                      <Typography variant="body2">
                        Quickly look at your school from many different angles. We&apos;ll do the
                        heavy lifting for you.
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot>
                      <PaymentIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={0}>
                      <Typography variant="h3" gutterBottom>
                        Manage Payments Online
                      </Typography>
                      <Typography variant="body2">
                        Keep the money; we&apos;ll do the bookkeeping. Pay online or make a record
                        of offline payments.
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Grid>
          </Grid>
        </Container>
        <Container disableGutters>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item xs={12}>
              <Img
                className={classes.featureImage}
                fluid={studentDetails ? studentDetails.childImageSharp.fluid : null}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    site: site{
      siteMetadata{
        title
        description
      }
    },
    studentList: file(relativePath: {eq: "katanga-student-list-screenshot.png"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3080) {
          ...GatsbyImageSharpFluid  
        }
      }
    },
    studentDetails: file(relativePath: {eq: "katanga-student-details-screenshot.png"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3080) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    logo: file(relativePath: {eq: "katanga-logo-wide.png"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3080) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    favicon: file(relativePath: {eq: "katanga-favicon.png"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
